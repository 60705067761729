var isSP = function() {
	return $('.sp-view:visible').length != 0;
};

// Retina対応
document.createElement('picture');

// scroll
$(function(){
    var headerHeight = $('header').outerHeight();
    var speed = 800;
    var urlHash = location.hash;
    if(urlHash) {
        $('html,body').stop().scrollTop(0);
        setTimeout(function(){
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
        }, 500);
    }
    $('a[href*="#"]' + 'a:not(.non-scroll)').click(function() {
        var href= $(this).attr("href");
        var split = href.split('#');
        var target = $(split[1] == '' ? 'html' : "#" + split[1]);
        var position = target.offset().top - headerHeight;
        $('html,body').stop().animate({scrollTop:position}, speed, 'swing');   
    });
});

// pagetop
$(function() {
	var topBtn = $('.pagetop');	
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
    topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
    });
});

// SP header menu
$(function() {
	$('.header-menu').on('click', function() {
			$(this).toggleClass('active');
			$('body').toggleClass('show');
	});
});

// accordion
$(function() {
	$('.accordion').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('active');
		
	});
	
	$('.sp-accordion').click(function() {
		if (isSP()) {
			$(this).next().slideToggle();
			$(this).toggleClass('active');
		}
	});
});

// tel link
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}

